// How to use : https://tomastrajan.medium.com/the-complete-guide-to-angular-material-themes-4d165a9d24d1
@mixin rebooking-form-step-theme($theme) {
  // Colors used for element backgrounds
  $bg-palette: map-get($theme, background);

  // Colors used for texts and icons
  $fg-palette: map-get($theme, foreground);

  // Main color most widely used across all screens and components
  $primary-palette: map-get($theme, primary);

  // Also known as the secondary color. Used for floating action buttons and interactive elements.
  $accent-palette: map-get($theme, accent);

  // Color to convey error state
  $warn-palette: map-get($theme, warn);

  // If it's dark theme or not
  $is-dark: map-get($theme, is-dark);

  // ----------------------------------------------

  app-rebooking-form-step {
    height: 100%;
    width: 100%;

    .main-card {
      padding: 32px;
    }

    // Responsive rendering

    @include media-breakpoint('lt-md') {
      .responsive-container {
        flex-flow: column;
        align-content: stretch;
        align-items: stretch;
        justify-content: center;
        > * {
          margin-inline-end: 0px !important;

          &:not(:last-child) {
            margin-block-end: 16px;
          }
        }
      }
    }

    @include media-breakpoint('lt-sm') {
      .card-content {
        // Full-height - header height
        max-height: calc(100% - 48px);
        max-width: 100%;
      }

      .main-card {
        padding: 16px;
      }
    }
  }
}
