// How to use : https://tomastrajan.medium.com/the-complete-guide-to-angular-material-themes-4d165a9d24d1
@mixin check-verification-code-step-theme($theme) {
  // Colors used for element backgrounds
  $bg-palette: map-get($theme, background);

  // Colors used for texts and icons
  $fg-palette: map-get($theme, foreground);

  // Main color most widely used across all screens and components
  $primary-palette: map-get($theme, primary);

  // Also known as the secondary color. Used for floating action buttons and interactive elements.
  $accent-palette: map-get($theme, accent);

  // Color to convey error state
  $warn-palette: map-get($theme, warn);

  // If it's dark theme or not
  $is-dark: map-get($theme, is-dark);

  // ----------------------------------------------

  app-check-verification-code-step {
    height: 100%;
    width: 100%;

    .otp-container {
      width: 100%;

      &:dir(rtl) {
        // We force to ltr because Roman numerals in Arabic are displayed from left to right
        direction: ltr;
      }

      .ngx-otp-input-box:focus {
        border: 1px solid map-get($accent-palette, default);
      }
    }

    .check-verification-code-step-container {
      padding: 32px;
    }

    // RESPONSIVE

    @include media-breakpoint('lt-sm') {
      .check-verification-code-step-container {
        padding: 16px;
      }
    }
  }
}
