@use '@angular/material' as mat;

@include mat.core();

// Required for multi-theming
mat.$theme-ignore-duplication-warnings: true;

// Shared styles
@import './app/shared/styles/index.scss';

// Components
@import './app/app.component.scss';
@import './app/pages/not-found/not-found.component.scss';

@import './app/pages/home/home.component.scss';
@import './app/pages/home/home/flight-informations/flight-informations.component.scss';
@import './app/pages/home/home/product-informations/product-informations.component.scss';
@import './app/pages/home/home/slice-details-dialog/slice-details-dialog.component.scss';

@import './app/pages/home/otp/check-verification-code-step/check-verification-code-step.component.scss';
@import './app/pages/home/otp/send-verification-code-step/send-verification-code-step.component.scss';

@import './app/pages/home/refund/bank-transfer/form-step/form-step.component.scss';
@import './app/pages/home/refund/common/confirmation-step/confirmation-step.component.scss';
@import './app/pages/home/refund/hyperwallet/form-step/form-step.component.scss';
@import './app/pages/home/refund/hyperwallet/refund-step/refund-step.component.scss';
@import './app/pages/home/refund/spreedly/form-step/form-step.component.scss';
@import './app/pages/home/refund/common/payout-error-step/payout-error-step.component.scss';

@import './app/pages/home/rebooking/confirmation-step/confirmation-step.component.scss';
@import './app/pages/home/rebooking/form-step/form-step.component.scss';
@import './app/pages/home/rebooking/review-step/review-step.component.scss';
@import './app/pages/home/rebooking/search-alternatives-step/search-alternatives-step.component.scss';
@import './app/pages/home/rebooking/search-alternatives-step/warning-bags-dialog/warning-bags-dialog.component.scss';
@import './app/pages/home/rebooking/search-alternatives-step/alternative-details-dialog/alternative-details-dialog.component.scss';

@import './app/shared/components/alternative-informations/alternative-informations.component.scss';
@import './app/shared/components/language-selector/language-selector.component.scss';
@import './app/shared/components/theme-selector/theme-selector.component.scss';
@import './app/shared/components/slice-informations/slice-informations.component.scss';
@import './app/shared/components/layout/layout.component.scss';
@import './app/shared/components/loader/loader.component.scss';
@import './app/shared/components/navbar/navbar.component.scss';

// Define a mixin for easier access
@mixin hc-airlines-dg-claims-components-theme($theme) {
  @include app-theme($theme);
  @include not-found-page-theme($theme);

  @include home-page-theme($theme);
  @include flight-informations-theme($theme);
  @include product-informations-theme($theme);
  @include slice-details-dialog-theme($theme);

  @include check-verification-code-step-theme($theme);
  @include send-verification-code-step-theme($theme);

  @include bank-transfer-form-step-theme($theme);
  @include refund-confirmation-step-theme($theme);
  @include hyperwallet-form-step-theme($theme);
  @include hyperwallet-refund-step-theme($theme);
  @include spreedly-form-step-theme($theme);
  @include payout-error-step-theme($theme);

  @include rebooking-confirmation-step-theme($theme);
  @include rebooking-form-step-theme($theme);
  @include rebooking-review-step-theme($theme);
  @include rebooking-search-alternatives-step-theme($theme);
  @include warning-bags-dialog-theme($theme);
  @include alternative-details-dialog-theme($theme);

  @include alternative-informations-theme($theme);
  @include language-selector-theme($theme);
  @include theme-selector-theme($theme);
  @include slice-informations-theme($theme);
  @include layout-theme($theme);
  @include loader-theme($theme);
  @include navbar-theme($theme);
}

// ------------------------------------------
// DEFAULT THEME
// ------------------------------------------

@import './app/shared/styles/themes/theme-light-hts.scss';

.theme-light-hts {
  // Apply the theme to all Angular Material Components
  @include mat.all-component-themes($light-hts-theme);

  // Apply the theme to the components of the project
  @include hc-airlines-dg-claims-components-theme($light-hts-theme);

  // Specific CSS rules for HTML tags
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  input,
  option,
  div,
  button {
    font-family: $light-hts-theme-font;
  }
}

// ------------------------------------------
// PARTNER THEMES
// ------------------------------------------

@import './app/shared/styles/themes/theme-light-y4.scss';

.theme-light-y4 {
  // Apply the theme to all Angular Material Components (override)
  @include mat.all-component-themes($light-y4-theme);

  // Apply the theme to the components of the project
  @include hc-airlines-dg-claims-components-theme($light-y4-theme);

  // Specific CSS rules for HTML tags
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  input,
  option,
  div,
  button {
    font-family: $light-y4-theme-font;
  }
}

// ------------------------------------------

@import './app/shared/styles/themes/theme-light-j9.scss';

.theme-light-j9 {
  // Apply the theme to all Angular Material Components (override)
  @include mat.all-component-themes($light-j9-theme);

  // Apply the theme to the components of the project
  @include hc-airlines-dg-claims-components-theme($light-j9-theme);

  // Specific CSS rules for HTML tags
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  input,
  option,
  div,
  button {
    font-family: $light-j9-theme-font;

    &:dir(rtl) {
      font-family: $light-j9-theme-font-rtl;
    }
  }
}

// ------------------------------------------
// GLOBAL STYLE
// ------------------------------------------

html,
body {
  height: 100%;
  margin: 0;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

// ------------------------------------------
// Global Overrides (Angular Material)
// ------------------------------------------

.responsive-dialog-container {
  max-width: 960px !important;
  margin: 16px;
  --mdc-dialog-supporting-text-color: black;

  @include media-breakpoint('lt-sm') {
    max-width: 100% !important;
    margin: 8px;

    .mat-mdc-dialog-content {
      padding: 0px !important;
    }
  }

  .mat-mdc-dialog-surface {
    border-radius: 8px;
  }

  .mat-mdc-dialog-title {
    background-color: #505050;
    --mdc-dialog-subhead-color: white;
    display: flex;
  }
}

.mdc-button {
  --mdc-filled-button-container-shape: 8px;
  --mdc-text-button-container-shape: 8px;
  --mdc-outlined-button-container-shape: 8px;
}

.mdc-text-field--outlined {
  --mdc-outlined-text-field-container-shape: 8px;
}

.mdc-card {
  --mdc-outlined-card-container-shape: 8px;
  --mdc-elevated-card-container-shape: 8px;
  --mdc-elevated-card-container-elevation: 0px 0px 3px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-icon {
  &:dir(rtl) {
    direction: rtl;
  }
}
