// How to use : https://tomastrajan.medium.com/the-complete-guide-to-angular-material-themes-4d165a9d24d1
@mixin spreedly-form-step-theme($theme) {
  // Colors used for element backgrounds
  $bg-palette: map-get($theme, background);

  // Colors used for texts and icons
  $fg-palette: map-get($theme, foreground);

  // Main color most widely used across all screens and components
  $primary-palette: map-get($theme, primary);

  // Also known as the secondary color. Used for floating action buttons and interactive elements.
  $accent-palette: map-get($theme, accent);

  // Color to convey error state
  $warn-palette: map-get($theme, warn);

  // If it's dark theme or not
  $is-dark: map-get($theme, is-dark);

  // Color for grey elements
  $grey-element: #aaaaaa;

  // ----------------------------------------------

  app-spreedly-form-step {
    height: 100%;
    width: 100%;

    .main-card {
      padding: 32px;

      .card-image {
        height: 52px;
        width: 90px;
      }

      .spreedly-container {
        position: relative;
        top: -0.75rem;
        height: 32px;
        margin: 16px 0px;

        .spreedly-label {
          color: var(--mdc-outlined-text-field-label-text-color);
          background: white;
          margin-inline-start: 8px;
          padding: 0px 8px;
          position: absolute;
          top: 18px;
          font-size: inherit;
          transition: all 0.2s ease;

          &:after {
            margin-left: 1px;
            margin-right: 0;
            content: '*';
          }
        }

        .spreedly-input {
          outline: 1px solid #9e9e9e;
          outline-offset: -1px;
          border-radius: 8px;
          height: 56px;

          iframe {
            height: 56px;
            width: 100%;
          }

          &:hover {
            outline: 1px solid black;
            outline-offset: -1px;
          }
        }

        &:hover {
          .spreedly-label {
            @include spreedly-label-minimized-properties();
          }
        }

        // Defined in SpreedlyService
        &.focus {
          .spreedly-label {
            @include spreedly-label-minimized-properties();
            color: map-get($accent-palette, default) !important;
          }

          .spreedly-input {
            outline: 2px solid map-get($accent-palette, default);
            outline-offset: -2px;
          }
        }

        // Defined in SpreedlyService
        &.touched {
          .spreedly-label {
            @include spreedly-label-minimized-properties();
            color: var(--mdc-outlined-text-field-label-text-color);
          }
        }

        // Defined in SpreedlyService
        &.error {
          .spreedly-label,
          .spreedly-input {
            color: map-get($warn-palette, default) !important;
          }

          .spreedly-input {
            outline-color: map-get($warn-palette, default);
          }
        }
      }
    }

    // Responsive rendering

    @include media-breakpoint('lt-md') {
      .responsive-container {
        flex-flow: column;
        align-content: stretch;
        align-items: stretch;
        justify-content: center;
        > * {
          margin-inline-end: 0px !important;

          &:not(:last-child) {
            margin-block-end: 16px;
          }
        }
      }

      .spreedly-container {
        margin-bottom: 8px !important;
      }

      .card-image {
        margin-top: 8px;
      }
    }

    @include media-breakpoint('lt-sm') {
      .card-content {
        // Full-height - header height
        max-height: calc(100% - 48px);
        max-width: 100%;
      }

      .main-card {
        padding: 16px;
      }
    }
  }
}

// Trick to rebuild an Angular mat-input behavior
@mixin spreedly-label-minimized-properties {
  background: white;
  margin-inline-start: 8px;
  padding: 0px 4px;
  position: absolute;
  top: -8px;
  font-size: 12px;
}
